import { useContext, useState } from 'react';

import AppContext from '../core/AppContext';
import ConfigCard from '../common/ConfigCard';
import { createOutline } from 'ionicons/icons';

function SettingsCard(props) {
	const appCtx = useContext(AppContext);

	async function updateJson(newJson) {
		await appCtx.updateFcmSettings(newJson);
		return newJson;
	}

	return (
		<ConfigCard
			title="Settings"
			icon={createOutline}
			json={props.settings}
			updateJson={updateJson}
		/>
	);
}

export default SettingsCard;