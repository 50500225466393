import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './components/core/AppContext';
import { useState } from 'react';
import { isAuthenticated } from './components/core/Utils';
import Main from './Main';
import { IonApp } from '@ionic/react';
import ServiceWorkerMessages from './components/core/ServiceWorkerMessages';

function App() {
	const [ authenticated, setAuthenticated ] = useState(isAuthenticated());

	return (
		<AppContextProvider authenticated={authenticated} setAuthenticated={setAuthenticated}>
			<BrowserRouter>
				<IonApp>
					<Main />
					<ServiceWorkerMessages/>
				</IonApp>
			</BrowserRouter>
		</AppContextProvider>
	);
}

export default App;
