import { useContext, useEffect, useState } from "react";
import AppContext from "../components/core/AppContext";
import { Redirect, useLocation } from 'react-router-dom';
import { IonLoading } from "@ionic/react";

function NotificationRedirectPage() {
    const appCtx = useContext(AppContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
	const url = (searchParams.get('url') || '').trim();
	const messageId = parseInt((searchParams.get('messageId') || '').trim());

    const [ shouldRedirect, setShouldRedirect ] = useState(false);

    useEffect(async () => {
        if (shouldRedirect) return;
        await appCtx.setFcmMessageClicked(messageId);
        setShouldRedirect(true);
    }, [shouldRedirect]);

    if (shouldRedirect) {
        return <Redirect to={url} />;
    }
    return <IonLoading message="Loading..."/>

}
export default NotificationRedirectPage;