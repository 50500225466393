import { useState } from 'react';
import { IonAlert } from '@ionic/react';

function InstallPrompt(props) {
	const [installPromptEvent, setInstallPromptEvent] = useState(undefined);

	window.addEventListener('beforeinstallprompt', (evt) => {
		evt.preventDefault();
        const lastDismissTimestamp = localStorage.getItem('pwaDismissTimestamp');
        const retryAfterDays = props.retryAfterDays || 0; // retry after half day
        const retryAfterMilliSeconds = retryAfterDays * 24 * 60 * 60 * 1000 // days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        if (lastDismissTimestamp === null || (new Date() - new Date(Date.parse(lastDismissTimestamp))) >= retryAfterMilliSeconds) {
            setInstallPromptEvent(evt);
        }
	});

    const alertButtons = [{
        text: 'Dismiss',
        role: 'cancel',
        handler: () => localStorage.setItem('pwaDismissTimestamp', new Date().toISOString()),
    }, {
        text: 'Install',
        handler: () => installPromptEvent.prompt(),
    }];

    return (
        <IonAlert
            isOpen={installPromptEvent !== undefined}
            message={props.message}
            header='BasselTech'
            buttons={alertButtons}
        />

    );
}

export default InstallPrompt;