import { useState, useEffect, useContext } from 'react';
import AppContext from './AppContext';

export function useScript(src, shouldLoad, customAttributes={}) {
	const [ status, setStatus ] = useState(src ? 'loading' : 'idle');
	const appCtx = useContext(AppContext);

	useEffect(
        () => {
			if (!shouldLoad || !appCtx.initialized) {
				setStatus('idle');
				return;
			}

			const script = document.createElement('script');
			script.src = src;
			script.crossOrigin = 'anonymous';
			Object.entries(customAttributes).map(value => script.setAttribute(value[0], value[1]));

			const setStateFromEvent = (event) => setStatus(event.type === 'load' ? 'ready' : 'error');

			script.addEventListener('load', setStateFromEvent);
			script.addEventListener('error', setStateFromEvent);
			document.body.appendChild(script);
		},
		[ src, shouldLoad, appCtx.initialized ] // Only re-run effect if script src changes
	);
	return status;
}
