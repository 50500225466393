import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon } from '@ionic/react';
import { useState } from 'react';

import classes from './ConfigCard.module.css';
import ConfigModal from '../dashboard/ConfigModal';

function ConfigCard(props) {
	const [ json, setJSON ] = useState(props.json);
	const [ showModal, setShowModal ] = useState();

	function modalSaveHandler(newJson) {
		props.updateJson(newJson).then(setJSON).then(modalDismissHandler);
	}

	function modalDismissHandler() {
		setShowModal(false);
	}

	function showModalHandler() {
		setShowModal(true);
	}

	return (
		<IonCard className={props.className}>
			<IonCardHeader>
				<IonCardTitle>
					<span className={classes.title}>{props.title}</span>

					<IonButton
						className={classes.right_button}
						size="small"
						fill="clear"
						onClick={showModalHandler}
					>
						<IonIcon icon={props.icon} slot="icon-only"/>
					</IonButton>
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent style={{'overflow': 'auto'}} className="custom-scrollbar">
				{json && (
					<table className={classes.table}>
						<thead>
							<tr>
								<th>Key</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(json).map((entry, i) => (
								<tr key={i}>
									<td>{entry[0]}</td>
									<td style={{'whiteSpace': 'pre', 'textAlign': 'left'}}>{JSON.stringify(entry[1], null, "\t")}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</IonCardContent>
			<ConfigModal
				showModal={showModal}
				title={`Update ${props.title}`}
				json={json}
				dismissHandler={modalDismissHandler}
				saveHandler={modalSaveHandler}
			/>
		</IonCard>
	);
}

export default ConfigCard;