import 'highlight.js/styles/github.css';

import { Component } from 'react';

import ReactMarkDown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import remarkCodeBlocks from 'remark-code-blocks';
import remarkEmoji from 'remark-emoji';

import classes from './Note.module.css';

class Note extends Component {
	render() {
		return (
			<ReactMarkDown
				className={classes.note}
				children={this.props.body}
				remarkPlugins={[ remarkGfm, remarkCodeBlocks, remarkEmoji ]}
				rehypePlugins={[ rehypeHighlight ]}
				components={{
					a: (props) => {
						const url = new URL(props.href, window.location.origin);
						const target = url.origin !== window.location.origin ? '_blank' : '_self';
						return <a href={props.href} target={target}>{props.children}</a>
					},
				}}
			/>
		);
	}
}

export default Note;
