import Routes from './components/core/Routes';
import { IonAlert, IonFab, IonFabButton, IonIcon, IonLoading } from '@ionic/react';
import Page from './components/layout/Page';

import { useState, useEffect, useContext, Fragment } from 'react';
import AppContext from './components/core/AppContext';
import NotificationPrompt from './components/core/NotificationPrompt';
import ConnectionToast from './components/core/ConnectionToast';
import InstallPrompt from './components/core/InstallPrompt';

import { routes } from './config';
import { useScript } from './components/core/Hooks';

import classes from './Main.module.css';

function Main() {
	const [ loadingState, setLoadingState ] = useState('loading');
	const appCtx = useContext(AppContext);

	const { googleTagContainerId } = appCtx.settings || {};

	const loadGoogleAdsScript = (googleAdClientId) => {
		if (!googleAdClientId) return;
		const googleAdsScript = document.createElement('script');
		googleAdsScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdClientId}`;
		googleAdsScript.setAttribute('async', true);
		googleAdsScript.setAttribute('crossOrigin', 'anonymous');
		googleAdsScript.setAttribute('data-ad-client', googleAdClientId);
		document.body.appendChild(googleAdsScript);
	}

	useEffect(async () => {
		if (appCtx.initialized) {
			try {
				const config = await appCtx.loadDatabase();
				const { showAds, googleAdClientId } = config.settings || {};
				loadGoogleAdsScript(showAds && googleAdClientId);
				setLoadingState('loaded');
			} catch (ex) {
				console.error("App Loading Error:", ex);
				setLoadingState('error');
			}
		}
	}, [ appCtx.initialized ]);


	useScript(`https://www.googletagmanager.com/gtm.js?id=${googleTagContainerId}`, googleTagContainerId, {
		async: true,
	});

	if (loadingState === 'loading') return <IonLoading isOpen={true} message="Loading..." />;
	if (loadingState === 'error') return (
		<IonAlert isOpen={true}
				  message="An error occurred while loading the app."
				  buttons={[{text: 'Reload', handler: () => window.location.reload()}]}/>
	);

	const menuItems = routes.filter(route => route.showAs === 'menu' && (route.visibility === 'always' || (route.visibility === 'auth' && appCtx.authenticated) || (route.visibility === 'no-auth' && !appCtx.authenticated)));
	const fabButtons = routes.filter(route => route.showAs === 'fab-button' && (route.visibility === 'always' || (route.visibility === 'auth' && appCtx.authenticated) || (route.visibility === 'no-auth' && !appCtx.authenticated)));

	const { notificationPromptRetryAfterDays, installPromptRetryAfterDays, refreshFcmTokenAfterDays } = appCtx.settings;

	return (
		<Fragment>
			<Page menuItems={menuItems}>
				<Routes routes={routes}/>
			</Page>
			<NotificationPrompt
				retryAfterDays={notificationPromptRetryAfterDays || 0}
				refreshFcmTokenAfterDays={refreshFcmTokenAfterDays || 0}
				message="Enable notifications to stay up-to-date with our latest tutorials & news."
			/>
			<InstallPrompt
				retryAfterDays={installPromptRetryAfterDays || 0}
				message="Install BasselTech's PWA to stay up-to-date?"
			/>
			<ConnectionToast/>
			{fabButtons.length > 0 && (
				<IonFab slot="fixed" className={classes.ion_fab}>
					{fabButtons.map(button => (
						<IonFabButton key={button.url} href={button.url} title={button.title} color="tertiary">
							<IonIcon icon={button.icon} title={button.title}/>
						</IonFabButton>
					))}
				</IonFab>
			)}
		</Fragment>
	);
}

export default Main;
