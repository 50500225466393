import { IonCard, IonCardHeader, IonCardTitle, IonButton, IonCardContent, IonChip, IonIcon } from '@ionic/react';
import { useContext, useState } from 'react';
import AppContext from '../core/AppContext';
import Modal from './Modal';

import classes from './ModalCard.module.css';
import { addOutline } from 'ionicons/icons';

function ModalCard(props) {
	const [ showModal, setShowModal ] = useState();
	const [ modalData, setModalData ] = useState({});

	const setItems = props.setItems;
	const items = props.items;

	const appCtx = useContext(AppContext);

	function modalDismissHandler() {
		setShowModal(false);
	}

	function deleteHandler(dataObj) {
		modalDismissHandler();
		appCtx.saveData(`/api/delete/${props.urlPostfix}`, dataObj, () => {
			setItems((prevItems) =>
				prevItems.filter((item) => {
					if (item.text === dataObj.old_text) return false;
					return true;
				})
			);
		});
	}

	function saveHandler(dataObj) {
		modalDismissHandler();
		appCtx.saveData(`/api/update/${props.urlPostfix}`, dataObj, () => {
			setItems((prevItems) =>
				prevItems.map((item) => {
					if (item.text === dataObj.old_text) {
						item.text = dataObj.text;
						item.is_featured = dataObj.is_featured;
					}
					return item;
				})
			);
		});
	}

	function addHandler(dataObj) {
		modalDismissHandler();
		appCtx.saveData(`/api/add/${props.urlPostfix}`, dataObj, () => {
			setItems((prevItems) =>
				prevItems.concat({
					text: dataObj.text,
					is_featured: dataObj.is_featured
				})
			);
		});
	}

	function addBtnHandler(evt) {
		setModalData({ title: '', isFeatured: false, saveHandler: addHandler });
		setShowModal(true);
	}

	function editBtnHandler(evt) {
		let index = parseInt(evt.target.dataset.key);
		let modalItemData = Object.assign({}, items[index]);

		modalItemData.saveHandler = saveHandler;
		modalItemData.deleteHandler = deleteHandler;

		setModalData(modalItemData);
		setShowModal(true);
	}

	return (
		<IonCard className={props.className}>
			<IonCardHeader>
				<IonCardTitle>
					<span className={classes.title}>{props.title}</span>

					<IonButton className={classes.right_button} size="small" fill="clear" onClick={addBtnHandler}>
						<IonIcon icon={addOutline} slot="icon-only"/>
					</IonButton>
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				{items.map((item, i) => (
					<IonChip key={i} data-key={i} onClick={editBtnHandler}>
						{item.text}
					</IonChip>
				))}
			</IonCardContent>
			<Modal title={props.title} data={modalData} showModal={showModal} dismissHandler={modalDismissHandler} />
		</IonCard>
	);
}

export default ModalCard;
