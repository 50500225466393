import { IonAlert, IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { sendOutline } from "ionicons/icons";
import { Fragment, useContext, useRef, useState } from "react";
import AppContext from "../core/AppContext";

function NotificationsModal({isOpen, tokens, settings, dismissHandler}) {
    const appCtx = useContext(AppContext);
    const [ state, setState ] = useState('draft');
    const [ successTokens, setSuccessTokens ] = useState([]);
    const [ failureTokens, setFailureTokens ] = useState([]);
    const loadingRef = useRef();
    const titleRef = useRef();
    const bodyRef = useRef();
    const clickActionRef = useRef();

    const sendNotificationForToken = async (token, messageId, {title, body, clickAction}) => {
        const dummyNode = document.createElement('div');
        dummyNode.innerHTML = body;
        const sanitizedBody = dummyNode.textContent;
        const searchParams = new URLSearchParams({
            url: clickAction,
            messageId: messageId,
        });
        return fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                'Authorization': `key=${settings.key}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                to: token,
                data: {
                    title: title,
                    body: sanitizedBody,
                    body_html: body,
                    click_action: `/notification_redirect?${searchParams.toString()}`,
                    icon: settings.message.icon,
                },
            }),
        }).then(res => res.json());
    }

    const getMessage = async () => {
        const message = {
            title: titleRef.current.value,
            body: bodyRef.current.value,
            clickAction: clickActionRef.current.value,
        }
        const messageId = await appCtx.createFcmMessage(message);
        console.log('Message ID:', messageId);
        return [ messageId, message ];
    }

    const sendNotification = async () => {
        setState('sending');
        const [ messageId, message ] = await getMessage();
        tokens.forEach(async (token) => {
            try {
                const res = await sendNotificationForToken(token, messageId, message);
                if (res.success > 0) {
                    setSuccessTokens(prevState => [...prevState, [token, messageId]]);
                } else {
                    setFailureTokens(prevState => [...prevState, [token, messageId]]);
                    console.warn("Failed to send notification for token:", token);
                }
            } catch (ex) {
                console.error("Sending Notifications Error:", ex);
                console.warn("Failed to send notification for token:", token);
                setFailureTokens(prevState => [...prevState, [token, messageId]]);
            }
        });
    }

    const reset = () => {
        setState('draft');
        setFailureTokens([]);
        setSuccessTokens([]);
    }

    const sentCount = successTokens.length + failureTokens.length;

    if (state === 'sending' && sentCount === tokens.length) {
        setState('done');
        appCtx.updateFcmSuccessFailureCount(successTokens, failureTokens);
    }

    if (state === 'sending' && loadingRef && loadingRef.current) {
        loadingRef.current.querySelector('.loading-content').innerText = `Sending ${sentCount} out of ${tokens.length}.`;
    }

    return (
        <Fragment>
            <IonLoading
                isOpen={state === 'sending'}
                message={`Sending ${sentCount} out of ${tokens.length}.`}
                ref={loadingRef}
            />
            <IonAlert
                isOpen={state === 'done'}
                message={`${successTokens.length} successes & ${failureTokens.length} failures.`}
                header="Notifications Report"
                buttons={['OK']}
                onDidDismiss={reset}
            />
            <IonModal isOpen={isOpen && state === 'draft'} onDidDismiss={dismissHandler}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Send Notifications</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">Title</IonLabel>
                            <IonInput type="text" ref={titleRef} required="1"/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Body</IonLabel>
                            <IonTextarea ref={bodyRef} required="1"/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Click Action</IonLabel>
                            <IonInput type="text" ref={clickActionRef} required="1" value="/"/>
                        </IonItem>
                    </IonList>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton onClick={sendNotification} color="primary" fill="clear" slot="start">
                            <IonIcon icon={sendOutline} slot="start"/> Send
                        </IonButton>
                        <IonButton onClick={dismissHandler} color="secondary" fill="clear" slot="end">Discard</IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
        </Fragment>
    );
}
export default NotificationsModal;