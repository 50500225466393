import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonButton,
	IonInput,
	useIonModal,
	IonContent,
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonIcon
} from '@ionic/react';

import { Fragment, useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import BrowseBySnippet from '../components/snippets/BrowseBySnippet';
import VideoToolbar from '../components/ui/VideoToolbar';

import Separator from '../components/ui/Separator';
import { Helmet } from 'react-helmet';

import classes from './Video.module.css';
import AppContext from '../components/core/AppContext';
import Note from '../components/layout/Note';
import VideoNavigator from '../components/ui/VideoNavigator';
import { closeOutline, codeSlashOutline, logoGithub } from 'ionicons/icons';

function VideoPage(props) {
	function editHandler() {
		setMode('edit');
	}

	function saveHandler() {
		appCtx
			.editVideo({
				id: currentVideo.id,
				source_url: sourceCode,
				extra_notes: extraNotes,
				is_featured: (featured && '1') || '0',
				tags: tags,
				languages: languages
			})
			.then(discardHandler);
	}

	function discardHandler() {
		setSourceCode(currentVideo.source_url);
		setExtraNotes(currentVideo.extra_notes);
		setLanguages(currentVideo.languages);
		setTags(currentVideo.tags);
		setFeatured(currentVideo.is_featured === '1');
		setMode('readonly');
	}

	function unFeatureHandler() {
		setFeatured(false);
	}

	function featureHandler() {
		setFeatured(true);
	}

	function notePreviewHandler(evt) {
		evt.preventDefault();
		setModalContent(
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Notes Preview</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={dismissModal}>
								<IonIcon icon={closeOutline} slot="icon-only"/>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Note body={extraNotes} />
				</IonContent>
			</IonPage>
		);
		presentModal();
	}

	window.addEventListener('resize', (evt) => {
		const videoIframe = document.querySelector('iframe');
		if (videoIframe) videoIframe.setAttribute('height', 1080 / 1920 * videoIframe.clientWidth);
	});

	const appCtx = useContext(AppContext);
	const currentVideo = props.videos[0];

	const [ sourceCode, setSourceCode ] = useState(currentVideo.source_url);
	const [ extraNotes, setExtraNotes ] = useState(currentVideo.extra_notes);

	const [ mode, setMode ] = useState('readonly');
	const [ languages, setLanguages ] = useState(currentVideo.languages);
	const [ tags, setTags ] = useState(currentVideo.tags);
	const [ featured, setFeatured ] = useState(currentVideo.is_featured === '1');

	const allLanguages = appCtx.languages;
	const allTags = appCtx.tags;

	const [ modalContent, setModalContent ] = useState();
	const [ presentModal, dismissModal ] = useIonModal(modalContent);

	useEffect(discardHandler, [ currentVideo ]);
	useEffect(() => props.breadcrumbsRef.current.scrollTo(1000, 0), [ currentVideo ]);

	return (
		<div>
			<Helmet>
				<title>BasselTech - {currentVideo.title}</title>
				<meta name="description" content={currentVideo.description.split('\n')[0]} />
				<meta
					name="keywords"
					content={currentVideo.title
						.split(' ')
						.concat(currentVideo.description.split('\n')[0].split(' '))
						.concat(tags)
						.concat(languages)
						.join(',')}
				/>
			</Helmet>
			{props.breadcrumbs}
			<IonCard className={classes.card}>
				<VideoToolbar
					mode={mode}
					featured={featured}
					editHandler={editHandler}
					saveHandler={saveHandler}
					discardHandler={discardHandler}
					featureHandler={featureHandler}
					unFeatureHandler={unFeatureHandler}
				/>

				<IonCardHeader>
					<IonCardTitle>{currentVideo.title}</IonCardTitle>
					<IonCardSubtitle>{new Date(currentVideo.date).toDateString()}</IonCardSubtitle>
					<Separator />
				</IonCardHeader>
				<IonCardContent>
					<section className="ion-text-center">
						<iframe
							className={classes.iframe}
							src={`https://www.youtube-nocookie.com/embed/${currentVideo.id}`}
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
						<div className={classes.caption}>{currentVideo.description.split('\n')[0]}</div>
					</section>
					{(languages.length > 0 || sourceCode || mode === 'edit') && (
						<section>
							<Separator />
							<div className={classes.lang_link_container}>
								{(languages.length > 0 || mode === 'edit') && (
									<BrowseBySnippet
										title="Programming Languages"
										icon={codeSlashOutline}
										urlPrefix="languages"
										contentList={languages}
										mode={mode}
										setState={setLanguages}
										allItems={allLanguages}
									/>
								)}
								{(sourceCode || mode === 'edit') && (
									<div className={classes.source_code}>
										{mode === 'readonly' && (
											<IonButton color="primary" fill="solid">
												{
													sourceCode.includes('github.com') ? (
														<Fragment>
															<IonIcon slot="start" icon={logoGithub}/>
															<Link
																to={`/redirect?url=${encodeURIComponent(sourceCode)}`}
																className={classes.download_link}
																title="View on GitHub"
															>
																View on GitHub
															</Link>
														</Fragment>
													) : (
														<Link
															to={`/redirect?url=${encodeURIComponent(sourceCode)}`}
															className={classes.download_link}
															title="Download Source Code"
														>
															Download Source Code
														</Link>
													)
												}
											</IonButton>
										)}
										{mode === 'edit' && (
											<IonInput
												type="text"
												onIonChange={(evt) => setSourceCode(evt.currentTarget.value)}
												placeholder="Source code url..."
												value={sourceCode}
											/>
										)}
									</div>
								)}
							</div>
						</section>
					)}

					{(tags.length > 0 || mode === 'edit') && (
						<section>
							<Separator />
							<BrowseBySnippet
								title="Tags"
								urlPrefix="tags"
								contentList={tags}
								mode={mode}
								setState={setTags}
								allItems={allTags}
							/>
						</section>
					)}

					<div/>

					{(extraNotes || mode === 'edit') && (
						<section>
							<Separator />
							<h2>
								Notes{' '}
								{mode === 'edit' && (
									<Link to="#" onClick={notePreviewHandler}>
										<small>(Preview)</small>
									</Link>
								)}
							</h2>
							{mode === 'readonly' && <Note className={classes.extra_notes} body={extraNotes} />}
							{mode === 'edit' && (
								<textarea
									className={classes.extra_notes_textarea}
									onChange={(evt) => setExtraNotes(evt.currentTarget.value)}
									defaultValue={extraNotes}
								/>
							)}
						</section>
					)}
					<VideoNavigator />
				</IonCardContent>
			</IonCard>
		</div>
	);
}

export default VideoPage;
