import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { Fragment, useContext } from 'react';

import Card from '../layout/Card';
import Separator from './Separator';
import AppContext from '../core/AppContext';
import { chevronBack, chevronForward } from 'ionicons/icons';

function VideoNavigator(props) {
	const appCtx = useContext(AppContext);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const videoId = searchParams.get('v');
	searchParams.delete('v');
	const [ , matchedVideos ] = appCtx.getFilteredVideos(searchParams);

	let previousVideo = undefined;
	let nextVideo = undefined;

	for (let i = 0; i < matchedVideos.length; i++) {
		if (i > 0 && matchedVideos[i - 1].id === videoId) {
			nextVideo = matchedVideos[i];
		} else if (i + 1 < matchedVideos.length && matchedVideos[i + 1].id === videoId) {
			previousVideo = matchedVideos[i];
		}
	}

	previousVideo = previousVideo && (
		<Card
			urlPrefix="v"
			content={previousVideo}
			renderMode="button"
			buttonPrefix={<IonIcon slot="start" icon={chevronBack}/>}
		/>
	);

	nextVideo = nextVideo && (
		<Card
			urlPrefix="v"
			content={nextVideo}
			renderMode="button"
			buttonPostfix={<IonIcon slot="end" icon={chevronForward}/>}
		/>
	);

	return previousVideo || nextVideo ? (
		<IonGrid>
			<IonRow>
				<IonCol size="12">
					<Separator />
				</IonCol>
			</IonRow>
			<IonRow className="ion-hide-sm-up">
				<IonCol size="12">{nextVideo}</IonCol>
				<IonCol size="12">{previousVideo}</IonCol>
			</IonRow>
			<IonRow className="ion-hide-sm-down">
				<IonCol sizeLg="5" sizeXs="12">{previousVideo}</IonCol>
				<IonCol sizeLg="2" sizeXs="0" />
				<IonCol sizeLg="5" sizeXs="12">{nextVideo}</IonCol>
			</IonRow>
		</IonGrid>
	) : (
		<Fragment />
	);
}

export default VideoNavigator;
