import { IonCard, IonButton, IonCardContent } from '@ionic/react';

import { Link, useLocation } from 'react-router-dom';

import classes from './Card.module.css';

function Card(props) {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const list = searchParams.get('list');
	const tags = searchParams.get('tags');
	const languages = searchParams.get('languages');

	const renderMode = props.renderMode || 'card';
	const buttonPrefix = props.buttonPrefix || '';
	const buttonPostfix = props.buttonPostfix || '';

	const contentDescription = (props.content.description || '').split('\n')[0].trim().replace(/\n*source code.*\n*.*\n*.*\n*.*\n*/gim, '');

	function getToURL() {
		var toUrlParts = [];
		if (list) toUrlParts.push(`list=${encodeURIComponent(list)}`);
		if (tags) toUrlParts.push(`tags=${encodeURIComponent(tags)}`);
		if (languages) toUrlParts.push(`languages=${encodeURIComponent(languages)}`);
		toUrlParts.push(`${props.urlPrefix}=${encodeURIComponent(props.content.id)}`);
		return `/watch?${toUrlParts.join('&')}`;
	}

	function scrollToTop() {
		document.querySelector('ion-app ion-content div[id="main"]').scrollTo(0, 0);
	}

	return (
		<Link className={classes.link} to={getToURL}>
			{renderMode === 'card' && (
				<IonCard className={`${classes.card} ${contentDescription === '' ? classes.no_description : ''}`} title={props.content.title}>
					<IonCardContent>
						<img src={props.content.thumbnail} alt={props.content.title} />
						<h4>
							<div className={classes.card_title}>{props.content.title}</div>
							<div className={classes.card_description}>{contentDescription}</div>
						</h4>
					</IonCardContent>
				</IonCard>
			)}

			{renderMode === 'button' && (
				<IonButton color="secondary" className={classes.button} onClick={scrollToTop}>
					{buttonPrefix}
					<span className={classes.button_title}>{props.content.title}</span>
					{buttonPostfix}
				</IonButton>
			)}
		</Link>
	);
}

export default Card;
