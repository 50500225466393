import HomePage from './pages/Home';
import WatchPage from './pages/Watch';
import LoginPage from './pages/Login';
import AboutPage from './pages/About';
import DashboardPage from './pages/Dashboard';
import RedirectPage from './pages/Redirect';

import { cogOutline, homeOutline, informationCircleOutline, logInOutline, notificationsCircleOutline, reloadOutline, videocamOutline } from 'ionicons/icons';
import NotificationsPage from './pages/Notifications';
import RequireAuth from './components/core/RequireAuth';
import NotificationRedirectPage from './pages/NotificationRedirect';
import PrivacyPolicyPage from './pages/PrivacyPolicy';

export const routes = [
    {
        title: 'Home',
        url: '/',
        component: <HomePage/>,
        icon: homeOutline,
        showAs: 'menu',
        visibility: 'always',
    },
    {
        title: 'Tutorials',
        url: '/watch',
        component: <WatchPage/>,
        icon: videocamOutline,
        showAs: 'menu',
        visibility: 'always',
    },
    {
        title: 'About Us',
        url: '/about-us',
        component: <AboutPage/>,
        icon: informationCircleOutline,
        showAs: 'menu',
        visibility: 'always',
    },
    {
        title: 'Login',
        url: '/login',
        component: <LoginPage/>,
        icon: logInOutline,
        showAs: 'fab-button',
        visibility: 'no-auth',
    },
    {
        title: 'Notifications Center',
        url: '/notifications',
        component: <RequireAuth><NotificationsPage/></RequireAuth>,
        icon: notificationsCircleOutline,
        showAs: 'fab-button',
        visibility: 'auth',
    },
    {
        title: 'Dashboard',
        url: '/dashboard',
        component: <RequireAuth><DashboardPage/></RequireAuth>,
        icon: cogOutline,
        showAs: 'fab-button',
        visibility: 'auth',
    },
    {
        title: 'Redirect',
        url: '/redirect',
        component: <RedirectPage/>,
        icon: reloadOutline,
        visibility: 'none',
    },
    {
        title: 'Notification Redirect',
        url: '/notification_redirect',
        component: <NotificationRedirectPage/>,
        icon: reloadOutline,
        visibility: 'none',
    },
    {
        title: 'Privacy Policy',
        url: '/privacy',
        component: <PrivacyPolicyPage/>,
        icon: reloadOutline,
        visibility: 'none',
    }
];
