import classes from './Navbar.module.css';
import { IonToolbar, IonMenuButton, IonTitle } from '@ionic/react';
import { Link } from 'react-router-dom';

function Navbar(props) {
	return (
		<IonToolbar className={classes.toolbar}>
			<nav className={classes.navbar}>
				<Link to="/" className={classes.brand}>
					<img src="./logo.svg" className={classes.brand} alt="brand-logo" type="image/svg+xml" />
				</Link>
				<IonTitle className='ion-hide-md-up ion-text-center'>Test</IonTitle>
				<ul className={classes.navbarList + ' ion-hide-md-down'}>
					{props.menuItems.map((menuItem, i) => (
						<Link to={menuItem.url} title={menuItem.title} key={i}>
							<li>{menuItem.title}</li>
						</Link>
					))}
				</ul>
			</nav>

			<IonMenuButton className={classes.navbarMenuButton + ' ion-hide-md-up'} slot="end" menu="mainMenu" />
		</IonToolbar>
	);
}

export default Navbar;
