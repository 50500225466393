import Navbar from '../ui/Navbar';
import NavbarMobile from '../ui/NavbarMobile';
import classes from './Page.module.css';

import { IonHeader } from '@ionic/react';

function Page(props) {
	return (
		<div className={classes.main}>
			<NavbarMobile menuItems={props.menuItems} />
			<IonHeader className={classes.header}>
				<Navbar menuItems={props.menuItems} />
			</IonHeader>
			{props.children}
		</div>
	);
}

export default Page;
