import { IonCard, IonCardContent, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import { useContext, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import AppContext from '../components/core/AppContext';

import classes from './Login.module.css';

import { Helmet } from 'react-helmet';

function LoginPage() {
	var loginRef = useRef();
	var passwordRef = useRef();

	const appCtx = useContext(AppContext);

	function loginHandler(evt) {
		evt.preventDefault();
		var login = loginRef.current.value;
		var password = passwordRef.current.value;
		appCtx.login(login, password);
		return false;
	}

	if (appCtx.authenticated) return <Redirect to="/dashboard" />;

	return (
		<div>
			<Helmet>
				<title>BasselTech - Login</title>
				<meta name="description" content="BasselTech Login" />
			</Helmet>
			<IonCard className={classes.card}>
				<IonCardContent>
					<h1 className="ion-text-center">BasselTech Login</h1>
					<IonList>
						<IonItem>
							<IonLabel position="floating">Login</IonLabel>
							<IonInput name="login" type="text" ref={loginRef} />
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Password</IonLabel>
							<IonInput name="password" type="password" ref={passwordRef} />
						</IonItem>
						<IonButton onClick={loginHandler} expand="full" id="btn-login" className={classes.btn_login}>
							Login
						</IonButton>
					</IonList>
				</IonCardContent>
			</IonCard>
		</div>
	);
}

export default LoginPage;
