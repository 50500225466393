import CardsGrid from '../layout/CardsGrid';
import classes from './TutorialsSnippet.module.css';

function TutorialsSnippet(props) {
	return (
		<section>
			<h2>{props.title}</h2>
			<CardsGrid
				className={classes.horizontal_grid}
				urlPrefix={props.urlPrefix}
				contentList={props.contentList}
			/>
		</section>
	);
}
export default TutorialsSnippet;
