import classes from './AppContent.module.css';
import { createPlatformCookie } from '../core/Utils';

function AppContent(props) {
	createPlatformCookie();
	window.adsbygoogle = [];

	setTimeout(() => {
		const ionTitle = document.body.querySelector('ion-app > div > ion-header ion-title');
		if (ionTitle) {
			ionTitle.innerText = props.title;
		}
	}, 0);

	return (
		<div className={`${classes.content} custom-scrollbar`} id="main">
			<div className={`${classes.adSectionLeft} ion-hide-md-down`}/>
			<div className={classes.mainSection}>
				<div className={classes.mainSectionContent}>{props.children}</div>
			</div>
			<div className={`${classes.adSectionRight} ion-hide-md-down`}/>
		</div>
	);
}

export default AppContent;
