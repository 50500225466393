import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonInput,
	IonCheckbox,
	IonFooter,
	IonButton,
	IonIcon
} from '@ionic/react';

import { useRef } from 'react';

import classes from './Modal.module.css';
import { saveOutline, trashOutline } from 'ionicons/icons';

function Modal(props) {
	const textRef = useRef();
	const isFeaturedRef = useRef();

	function saveHandler() {
		props.data.saveHandler({
			old_text: props.data.text,
			text: textRef.current.value,
			is_featured: (isFeaturedRef.current.checked && '1') || '0'
		});
	}

	function deleteHandler() {
		props.data.deleteHandler({
			old_text: props.data.text,
			text: textRef.current.value,
			is_featured: (isFeaturedRef.current.checked && '1') || '0'
		});
	}

	return (
		<IonModal isOpen={props.showModal} onDidDismiss={props.dismissHandler}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{props.title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					<IonItem>
						<IonLabel>Text</IonLabel>
						<IonInput ref={textRef} type="text" value={props.data.text} />
					</IonItem>
					<IonItem>
						<IonLabel>Is Featured</IonLabel>
						<IonCheckbox ref={isFeaturedRef} checked={props.data.is_featured === '1'} />
					</IonItem>
				</IonList>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonButton onClick={saveHandler} color="primary" fill="clear" slot="start">
						<IonIcon icon={saveOutline} slot="start"/> Save
					</IonButton>

					{props.data.deleteHandler && (
						<IonButton onClick={deleteHandler} color="danger" fill="clear" slot="start">
							<IonIcon icon={trashOutline} slot="start"/> Delete
						</IonButton>
					)}

					<IonButton
						onClick={props.dismissHandler}
						slot="end"
						color="secondary"
						fill="clear"
					>
						Discard
					</IonButton>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
}

export default Modal;
