import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon } from '@ionic/react';
import { useContext, useState } from 'react';

import AppContext from '../core/AppContext';
import classes from './DatabaseCard.module.css';
import { refreshOutline } from 'ionicons/icons';

function DatabaseCard(props) {
	const appCtx = useContext(AppContext);
	const [ lastUpdate, setLastUpdate ] = useState(appCtx.dbLastUpdate);

	function updateDatabaseHandler() {
		appCtx.updateDatabase().then(setLastUpdate);
	}

	return (
		<IonCard className={props.className}>
			<IonCardHeader>
				<IonCardTitle>
					<span className={classes.title}>YouTube Database</span>

					<IonButton
						className={classes.right_button}
						size="small"
						fill="clear"
						onClick={updateDatabaseHandler}
					>
						<IonIcon icon={refreshOutline} slot="icon-only"/>
					</IonButton>
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<p>Last updated on:</p>
				<p className={classes.date}>{new Date(lastUpdate).toUTCString()}</p>
			</IonCardContent>
		</IonCard>
	);
}

export default DatabaseCard;
