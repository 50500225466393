import { IonGrid, IonRow, IonCol } from '@ionic/react';
import Card from './Card';

import classes from './CardsGrid.module.css';

function CardsGrid(props) {
	return (
		<IonGrid className={props.className}>
			<IonRow className="custom-scrollbar">
				{props.contentList.map((content, i) => (
					<IonCol className={classes.col} key={i} sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="3">
						<Card urlPrefix={props.urlPrefix} content={content} />
					</IonCol>
				))}
			</IonRow>
		</IonGrid>
	);
}

export default CardsGrid;
