import TutorialsSnippet from '../components/snippets/TutorialsSnippet';
import Separator from '../components/ui/Separator';
import BrowseBySnippet from '../components/snippets/BrowseBySnippet';

import classes from './Home.module.css';

import { IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonButton } from '@ionic/react';

import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useContext } from 'react';

import AppContext from '../components/core/AppContext';

function HomePage(props) {
	const appCtx = useContext(AppContext);

	const featuredPlaylists = appCtx.getFeaturedPlaylists();
	const featuredVideos = appCtx.getFeaturedVideos();

	const featuredTags = appCtx.getFeaturedTags();
	const featuredLanguages = appCtx.getFeaturedLanguages();

	return (
		<div>
			<Helmet>
				<title>BasselTech - Home</title>
				<meta
					name="description"
					content="IT tutorials covering basics of programming, web development, electronics and logic design."
				/>
				<meta
					name="keywords"
					content="basseltech,it,free,tech,technology,programming,coding,code,vb.net,c#,python,java,php,mysql,sql,telegram,bot,chatbot,barcode,qr code,electronics,logic design,arduino,pcb,printed circuit board,breadboard,tutorials,diy,course,youtube,bassel,akmal,elerian"
				/>
			</Helmet>
			<section className={classes.banner}>
				<IonCard>
					<IonCardHeader>
						<IonCardTitle>Welcome to BasselTech!</IonCardTitle>
						<IonCardContent>
							<p>
								IT tutorials covering basics of programming, web development, electronics and logic
								design.
							</p>
							<IonButton title="Explore Tutorials" color="tertiary">
								<Link to="/watch">Explore Tutorials</Link>
							</IonButton>
						</IonCardContent>
					</IonCardHeader>
				</IonCard>
			</section>
			<section>
				{featuredVideos.length > 0 && (
					<section>
						<TutorialsSnippet title="Featured Videos" urlPrefix="v" contentList={featuredVideos} />
						<Separator />
					</section>
				)}

				{featuredPlaylists.length > 0 && (
					<section>
						<TutorialsSnippet title="Featured Playlists" urlPrefix="list" contentList={featuredPlaylists} />
						<Separator />
					</section>
				)}

				{featuredTags.length > 0 && (
					<section>
						<BrowseBySnippet
							title="Featured Tags"
							urlPrefix="tags"
							contentList={featuredTags}
							allItems={featuredTags}
						/>
						<Separator />
					</section>
				)}

				{featuredLanguages.length > 0 && (
					<section>
						<BrowseBySnippet
							title="Featured Languages"
							urlPrefix="languages"
							contentList={featuredLanguages}
							allItems={featuredLanguages}
						/>
					</section>
				)}
			</section>
		</div>
	);
}

export default HomePage;
