import classes from './Breadcrumbs.module.css';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumbs(props) {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	var breadcrumbs = [
		{
			title: 'Tutorials',
			url: '/watch'
		}
	];

	if (props.playlists.length === 1)
		breadcrumbs.push({
			title: props.playlists[0].title,
			url: `/watch?list=${encodeURIComponent(props.playlists[0].id)}`
		});

	if (searchParams.get('tags'))
		breadcrumbs.push({
			title: `Tags: ${searchParams.get('tags').split(',').join(' or ')}`,
			url: `/watch?tags=${encodeURIComponent(searchParams.get('tags'))}`
		});

	if (searchParams.get('languages'))
		breadcrumbs.push({
			title: `Languages: ${searchParams.get('languages').split(',').join(' or ')}`,
			url: `/watch?languages=${encodeURIComponent(searchParams.get('languages'))}`
		});

	if (props.videos.length === 1)
		breadcrumbs.push({
			title: props.videos[0].title,
			url: `/watch?v=${encodeURIComponent(props.videos[0].id)}`,
			active: true
		});

	return (
		<ul className={`${classes.breadcrumbs} custom-scrollbar`} ref={props.breadcrumbsRef}>
			{breadcrumbs.map((breadcrumb, i) => (
				<li key={i}>
					<Link
						className={`${classes.breadcrumb_link} ${breadcrumb.active ? classes.active : ''}`}
						to={breadcrumb.url}
						title={breadcrumb.title}
					>
						<span>{breadcrumb.title}</span>
					</Link>
				</li>
			))}
		</ul>
	);
}

export default Breadcrumbs;
