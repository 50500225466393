import { useState, useContext, useEffect, Fragment } from 'react';
import ToastPrompt from './ToastPrompt';
import AppContext from './AppContext';

import {
    clearLastTokenRefreshTimestamp,
    getLastTokenRefreshTimestamp,
    getLastNotificationDismissTimestamp,
    setLastNotificationDismissTimestamp,
    setLastTokenRefreshTimestamp
} from './Utils';

import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

function NotificationPrompt(props) {
    function daysToMilliSeconds(days) {
        return days * 24 * 60 * 60 * 1000 // days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    }
    const appCtx = useContext(AppContext);
    const { firebaseAppConfiguration, firebaseVapidKey } = appCtx.settings;

    const lastDismissTimestamp = getLastNotificationDismissTimestamp();
    const lastTokenRefreshTimestamp = getLastTokenRefreshTimestamp();

    const retryAfterMilliSeconds = daysToMilliSeconds(props.retryAfterDays);
    const refreshFcmTokenAfterMilliSeconds = daysToMilliSeconds(props.refreshFcmTokenAfterDays);

	const [ showNotificationPermission, setShowNotificationPermission ] = useState(
        (
            lastDismissTimestamp === null ||
            new Date() - new Date(Date.parse(lastDismissTimestamp)) >= retryAfterMilliSeconds
        ) && 'Notification' in window && Notification.permission === 'default'
    );
    const [ shouldAcquireFcmToken, setShouldAcquireFcmToken ] = useState(
        (
            lastTokenRefreshTimestamp === null ||
            new Date() - new Date(Date.parse(lastTokenRefreshTimestamp)) >= refreshFcmTokenAfterMilliSeconds
        ) && 'Notification' in window && Notification.permission === 'granted'
    );

    const [ fcmNotification, setFcmNotification ] = useState(undefined);

    // Firebase Configuration & Initialization
    const firebaseApp = initializeApp(firebaseAppConfiguration);
    const messaging = getMessaging(firebaseApp);

    useEffect(() => {
        if (shouldAcquireFcmToken && firebaseApp && messaging && firebaseVapidKey) {
            async function acquireFirebaseToken() {
                if ('Notification' in window && Notification.permission === "granted") {
                    return getToken(messaging, { vapidKey: firebaseVapidKey });
                } else if ('Notification' in window && Notification.permission !== "denied") {
                    const permission = await Notification.requestPermission();
                    if (permission === "granted") {
                        return getToken(messaging, { vapidKey: firebaseVapidKey });
                    }
                }
                throw new Error("Could not acquire FCM token");
            }

            function onTokenError(error) {
                console.error(error);
                clearLastTokenRefreshTimestamp();
            }

            acquireFirebaseToken().then(appCtx.registerFcmToken)
                                  .then(() => setShouldAcquireFcmToken(false))
                                  .then(setLastTokenRefreshTimestamp)
                                  .catch(onTokenError);
        }
    }, [ shouldAcquireFcmToken, firebaseApp, messaging, firebaseVapidKey ]);

    onMessage(messaging, (payload) => {
        console.log(payload);
        if (!('data' in payload)) return;

        const { title, body, body_html, icon, click_action } = payload.data;
        const data = { url: click_action };

        if ('serviceWorker' in navigator && !document.hasFocus()) {
            navigator.serviceWorker.ready.then(function(serviceWorker) {
                const notification = new Notification(title, { body, icon, data });
                notification.onclick = (evt) =>  evt.target.data.url && (window.location.href = evt.target.data.url);
                serviceWorker.showNotification(notification);
            });
        } else {
            setFcmNotification({ title, body, body_html, data });
        }
    });

    const toastButtons = [{
        text: 'Enable',
        side: 'end',
        handler: () => setShouldAcquireFcmToken(true),
    }, {
        text: 'Dismiss',
        role: 'cancel',
        side: 'end',
        handler: () => {
            setLastNotificationDismissTimestamp();
            setShowNotificationPermission(false);
        },
    }];

    const notificationToastButtons = [{
        text: 'Dismiss',
        role: 'cancel',
        side: 'end',
        handler: () => setFcmNotification(undefined),
    }];

    if (fcmNotification && fcmNotification.data.url) {
        notificationToastButtons.unshift({
            text: 'View',
            side: 'end',
            handler: () => {
                setFcmNotification(undefined);
                window.location.href = fcmNotification.data.url;
            },
        });
    }

    return (
        <Fragment>
            <ToastPrompt
                isOpen={showNotificationPermission}
                message={props.message}
                color="secondary"
                buttons={toastButtons}
            />
            { fcmNotification && (
                <ToastPrompt
                    isOpen={true}
                    title={fcmNotification.title}
                    message={fcmNotification.body_html || fcmNotification.body}
                    buttons={notificationToastButtons}
                />
            ) }
        </Fragment>
    );
}

export default NotificationPrompt;