import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../components/core/AppContext";

import { Helmet } from 'react-helmet';
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import SettingsCard from "../components/notifications/SettingsCard";
import TokensCard from "../components/notifications/TokensCard";

function NotificationsPage() {
    const appCtx = useContext(AppContext);

    const [ tokensConfig, setTokensConfig ] = useState([]);
    const [ loadingState, setLoadingState ] = useState('loading');

    useEffect(async () => {
        try {
            setTokensConfig(await appCtx.readFcmTokens());
            setLoadingState('loaded');
        } catch (ex) {
            console.error(ex);
            setLoadingState('error');
        }
    }, []);

    if (loadingState == 'loading') return <h3>Loading the FCM tokens & configuration...</h3>
    if (loadingState == 'error') return <h3>An error occurred while loading the FCM tokens & configuration!</h3>

    const { tokens, settings } = tokensConfig;
    if (!settings) return <h3>You need to configure the FCM settings first!</h3>

    return (
        <Fragment>
            <Helmet>
				<title>BasselTech - Notifications Center</title>
				<meta name="description" content="BasselTech Notifications Center" />
			</Helmet>
            <IonGrid>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="12">
                        <TokensCard tokens={tokens} settings={settings}/>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="12">
                        <SettingsCard settings={settings}/>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </Fragment>
    );
}

export default NotificationsPage;