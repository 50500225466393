import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow, IonTextarea } from "@ionic/react";

import classes from './TokensCard.module.css';
import { Fragment, useContext, useState } from "react";
import NotificationsModal from "./NotificationsModal";
import AppContext from "../core/AppContext";
import { checkmarkCircleOutline, closeCircleOutline, sendOutline } from "ionicons/icons";

function TokensCard({ tokens, settings }) {
    const appCtx = useContext(AppContext);
    const [ selectedTokens, setSelectedTokens ] = useState([]);
    const [ showNotificationsModal, setShowNotificationsModal ] = useState(false);

    const onChangeHandler = (evt) => {
        const token = evt.target.name;
        const checked = evt.detail.checked;
        if (checked) {
            setSelectedTokens((prevState) => prevState.includes(token) ? prevState : [...prevState, token]);
        } else {
            setSelectedTokens((prevState) => prevState.filter(t => t != token));
        }
    }

    const allTokensSelected = tokens.length > 0 && (tokens.length === selectedTokens.length);

    return (
        <Fragment>
            <NotificationsModal isOpen={showNotificationsModal}
                                tokens={selectedTokens}
                                settings={settings}
                                dismissHandler={() => setShowNotificationsModal(false)}/>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        Registered Tokens
                    </IonCardTitle>
                    <IonCardSubtitle>
                        <IonButtons>
                            {!allTokensSelected && (
                                <IonButton fill="clear" onClick={() => setSelectedTokens(tokens.map(token => token.token))}>
                                    <IonIcon icon={checkmarkCircleOutline} slot="start"/> All
                                </IonButton>
                            )}
                            {selectedTokens.length > 0 && (
                                <Fragment>
                                    <IonButton fill="clear" onClick={() => setSelectedTokens([])}>
                                        <IonIcon icon={closeCircleOutline} slot="start"/> Clear
                                    </IonButton>
                                    <IonButton fill="clear" onClick={() => setShowNotificationsModal(true)}>
                                        <IonIcon icon={sendOutline} slot="start"/> Send
                                    </IonButton>
                                </Fragment>
                            )}
                        </IonButtons>
                        {selectedTokens.length > 0 && `(${selectedTokens.length} selected)`}
                    </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className={classes.card_content}>
                    <IonList className={`${classes.list} custom-scrollbar`}>
                        {tokens.map(token => (
                            <IonItem key={token.token}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonList className={classes.inner_list}>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">IP Address:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.ip_address}/>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Platform:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.platform}/>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Successful Deliveries:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.success_count}/>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Failed Deliveries:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.failure_count}/>
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonList className={classes.inner_list}>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Create Date:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.create_date}/>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Write Date:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.write_date}/>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Last Success Date:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.last_success_date}/>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">Last Failure Date:</IonLabel>
                                                        <IonInput type="text" readonly="1" value={token.last_failure_date}/>
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <IonList className={classes.inner_list}>
                                                    <IonItem lines="none">
                                                        <IonLabel position="stacked">User Agent:</IonLabel>
                                                        <IonTextarea readonly="1" value={token.user_agent}/>
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                <IonCheckbox
                                    slot="start"
                                    name={token.token}
                                    checked={selectedTokens.includes(token.token)}
                                    onIonChange={onChangeHandler}
                                    className={classes.checkbox}
                                />
                            </IonItem>
                        ))}
                    </IonList>
                </IonCardContent>
            </IonCard>
        </Fragment>
    );
}

export default TokensCard;