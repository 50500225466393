import { Fragment, useState } from "react";
import ToastPrompt from "./ToastPrompt";
import { clearLastTokenRefreshTimestamp, getAppBuildVersion, setAppBuildVersion } from "./Utils";

function ServiceWorkerMessages() {
    function handleNavigation(evt) {
        if (evt.data.navigationUrl){
            window.location.href = evt.data.navigationUrl
        };
    }

    function handleNewServiceWorkerVersion(evt) {
        const appBuildVersion = getAppBuildVersion();
        const newAppVersion = evt.data.updatedVersion;
        if (!appBuildVersion) {
            setAppBuildVersion(newAppVersion);
        } else if (newAppVersion !== appBuildVersion) {
            clearLastTokenRefreshTimestamp();
            setAppBuildVersion(newAppVersion);
            setAppVersion(newAppVersion);
        }
    }

    const [ appVersion, setAppVersion ] = useState(undefined);

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (evt) => {
            if ('navigationUrl' in evt.data) {
                return handleNavigation(evt);
            } else if ('updatedVersion' in evt.data) {
                return handleNewServiceWorkerVersion(evt);
            }
        });
    }

    if (appVersion) {
        const buttons = [{
            text: 'Reload',
            role: 'cancel',
            side: 'end',
            handler: () => window.location.reload(),
        }];
        return (
            <ToastPrompt
                isOpen={true}
                message={`The app was automatically updated to the latest version v${appVersion}.`}
                buttons={buttons}
            />
        );
    }

    return <Fragment/>
}

export default ServiceWorkerMessages;