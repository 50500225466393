import { Route, Switch, Redirect } from 'react-router-dom';

import AppContent from '../layout/AppContent';
import { IonContent } from '@ionic/react';

function Routes({ routes }) {
	return (
		<Switch>
			{routes.map((route, i) => (
				<Route key={route.url} path={route.url} exact>
					<IonContent>
						<AppContent title={route.title}>
							{route.component}
						</AppContent>
					</IonContent>
				</Route>
			))}
			<Route path="/">
				<Redirect to="/"/>
			</Route>
		</Switch>
	);
}

export default Routes;
