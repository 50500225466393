import { createContext, useState } from 'react';
import { useIonLoading, useIonAlert } from '@ionic/react';
import { getAuthToken, getFcmToken, isAuthenticated, setFcmToken } from './Utils';

const AppContext = createContext({
	playlists: [],
	tags: [],
	languages: [],
	getFeaturedPlaylists: () => {},
	getFeaturedVideos: () => {},
	getFilteredVideos: (searchParams) => {},
	editVideo: (videoDetails) => {},
	getFeaturedTags: () => {},
	getFeaturedLanguages: () => {},
	editPlaylist: (playlistDetails) => {},
	dbLastUpdate: false,
	updateDatabase: () => {},
	saveData: async (url, detailsObj, successHandler) => {},
	setTags: (tags) => {},
	setLanguages: (languages) => {},
	login: async (login, password) => {},
	logout: async () => {},
	loadDatabase: async () => {},
	getVideoBySourceURL: (source_url) => {},
	settings: {},
	updateSettings: (settings) => {},
	registerFcmToken: async (token) => {},
	readFcmTokens: async () => {},
	updateFcmSettings: async (settings) => {},
	updateFcmSuccessFailureCount: async (successTokens, failureTokens) => {},
	createFcmMessage: async (message) => {},
	setFcmMessageClicked: async (messageId) => {},
	initialized: false,
});

export function AppContextProvider(props) {
	const [ data, setData ] = useState({});
	const [ languages, setLanguages ] = useState([]);
	const [ tags, setTags ] = useState([]);

	const [ config, setConfig ] = useState({});

	const [ presentLoading, dismissLoading ] = useIonLoading();
	const [ presentAlert ] = useIonAlert();

	const authenticated = props.authenticated;
	const setAuthenticated = props.setAuthenticated;

	async function saveData(url, detailsObj, successHandler) {
		presentLoading('Updating...');
		return fetchData(url, detailsObj).then((response) => {
			if (response.token_status !== 'OK') {
				dismissLoading();
				presentAlert('Token expired. Please login again!');
			} else if (response.status !== 'OK') {
				dismissLoading();
				presentAlert('Could not perform the requested updates!');
			} else {
				var res = successHandler(response);
				dismissLoading();
				return res;
			}
		}).catch((ex) => {
			dismissLoading();
			presentAlert('Could not perform the requested updates!');
		});
	}

	function editVideo(videoDetails) {
		return saveData('/api/update/video', videoDetails, () => {
			setData((prevData) => {
				var updatedFlag = false;
				prevData.playlists.forEach((playlist) => {
					playlist.videos.forEach((video) => {
						if (video.id === videoDetails.id) {
							// Update video
							video.source_url = videoDetails.source_url;
							video.extra_notes = videoDetails.extra_notes;
							video.is_featured = videoDetails.is_featured;
							video.tags = videoDetails.tags;
							video.languages = videoDetails.languages;
							updatedFlag = true;
							return;
						}
					});
					if (updatedFlag) return;
				});
				return prevData;
			});
		});
	}

	function updateDatabase() {
		return saveData('/api/update/db', {}, (response) => {
			setData((prevData) => {
				prevData.dbLastUpdate = response['last_update'];
				return prevData;
			});
			return response['last_update'];
		}).then((dbLastUpdate) => {
			saveData('/api/update/sitemap', {}, () => {});
			return dbLastUpdate;
		});
	}

	function updateSettings(settings) {
		return saveData('/api/update/settings', settings, (response) => {
			setConfig((prevConfig) => {
				prevConfig.settings = settings;
				return prevConfig;
			});
			return config.settings;
		});
	}

	async function loadDatabase() {
		return fetchData('/api/read/db', {}, 'GET').then((dataJSON) => {
			setLanguages(dataJSON.languages);
			setTags(dataJSON.tags);

			const jsonConfig = dataJSON.config;

			setConfig(jsonConfig);

			delete dataJSON['languages'];
			delete dataJSON['tags'];
			delete dataJSON['config'];

			setData(dataJSON);
			return jsonConfig;
		});
	}

	async function registerFcmToken(token) {
		const response = await fetchData('/api/fcm/register_token', { token }, 'POST');
		if (response.status === 'OK') {
			setFcmToken(token);
			console.log('FCM token was successfully registered!');
		} else {
			console.error('Failed to register FCM token!');
		}
	}

	async function readFcmTokens() {
		return fetchData('/api/fcm/read_tokens', {});
	}

	async function updateFcmSettings(settings) {
		return saveData('/api/fcm/update_settings', settings, () => {});
	}

	async function updateFcmSuccessFailureCount(successTokens, failureTokens) {
		return saveData('/api/fcm/update_success_failure', {successTokens, failureTokens}, () => {});
	}

	async function setFcmMessageClicked(messageId) {
		const fcmToken = getFcmToken() || '';
		return fetchData('/api/fcm/set_fcm_message_clicked', {messageId, fcmToken});
	}

	async function createFcmMessage(message) {
		return saveData('/api/fcm/create_message', message, (res) => res.messageId);
	}

	function editPlaylist(playlistDetails) {
		return saveData('/api/update/playlist', playlistDetails, () => {
			setData((prevData) => {
				var updatedFlag = false;
				prevData.playlists.forEach((playlist) => {
					if (playlist.id === playlistDetails.id) {
						playlist.is_featured = playlistDetails.is_featured;
						updatedFlag = true;
					}
					if (updatedFlag) return;
				});
				return prevData;
			});
		});
	}

	function getFeaturedPlaylists() {
		return data.playlists.filter((playlist) => playlist.is_featured === '1');
	}

	function getFeaturedVideos() {
		const videos = data.playlists.flatMap((playlist) =>
			playlist.videos.filter((video) => video.is_featured === '1').map((video) => [ video.id, video ])
		);
		return Object.values(Object.fromEntries(videos));
	}

	function getFeaturedTags() {
		return tags.filter((tag) => tag.is_featured === '1').map((tag) => tag.text);
	}

	function getFeaturedLanguages() {
		return languages.filter((language) => language.is_featured === '1').map((language) => language.text);
	}

	function getFilteredVideos(searchParams) {
		const list = searchParams.get('list');
		const tags = searchParams.get('tags');
		const languages = searchParams.get('languages');
		const v = searchParams.get('v');

		if (!(list || tags || languages || v)) return [ [], [] ];

		var matchedVideoIds = new Set();

		function videosFilter(video) {
			if (matchedVideoIds.has(video.id)) return [ true, undefined ];

			var includeVideo = true;

			if (tags) {
				const matchedTags = video.tags.filter((videoTag) => tags.split(',').includes(videoTag));
				includeVideo &= matchedTags.length > 0;
			}
			if (languages) {
				const matchedLanguages = video.languages.filter((videoLanguage) =>
					languages.split(',').includes(videoLanguage)
				);
				includeVideo &= matchedLanguages.length > 0;
			}

			if (v) {
				const matchedVideo = v.split(',').includes(video.id);
				includeVideo &= matchedVideo;
			}

			if (includeVideo) matchedVideoIds.add(video.id);

			return [ false, includeVideo ];
		}

		var matchedPlaylists = [];
		if (list) {
			matchedPlaylists = data.playlists.filter((playlist) => list.split(',').includes(playlist.id));
		} else {
			matchedPlaylists = data.playlists;
		}

		var playlistsInResult = new Set();

		var matchedVideos = matchedPlaylists.flatMap((playlist) => {
			var matchedVideosInPlaylist = playlist.videos.filter((video) => {
				const [ isDuplicate, includeVideo ] = videosFilter(video);
				if (isDuplicate || includeVideo) playlistsInResult.add(playlist.id);
				return includeVideo;
			});
			return matchedVideosInPlaylist;
		});

		matchedPlaylists = data.playlists.filter((playlist) => playlistsInResult.has(playlist.id));

		return [ matchedPlaylists, matchedVideos ];
	}

	function getVideoBySourceURL(source_url) {
		return data.playlists.flatMap((playlist) => playlist.videos.filter((video) => video.source_url === source_url));
	}

	async function fetchData(url, data={}, requestType='POST') {
		let headers = {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		}

		if (isAuthenticated()) {
			headers.authorization = getAuthToken();
		}

		const response = await fetch(url, {
			method: requestType,
			credentials: 'same-origin',
			headers,
			body: requestType === 'POST' ? JSON.stringify(data) : undefined,
		});
		const responseJson = await response.json();
		if (responseJson.token_status && responseJson.token_status !== 'OK') removeAuthToken();
		return responseJson;
	}

	function removeAuthToken() {
		localStorage.removeItem('token');
		setAuthenticated(false);
	}

	function setAuthToken(authToken) {
		localStorage.setItem('token', authToken);
		setAuthenticated(true);
	}

	// Session management methods
	async function login(login, password) {
		return fetchData('/api/auth/login', {
			login: login,
			password: password
		}).then((response) => {
			if (response.status === 'OK') {
				setAuthToken(response.token);
			} else presentAlert('Wrong login credentials!');
		});
	}

	async function logout() {
		return fetchData('/api/auth/logout').then(removeAuthToken);
	}

	const context = {
		playlists: data.playlists,
		getFeaturedPlaylists: getFeaturedPlaylists,
		getFeaturedVideos: getFeaturedVideos,
		getFilteredVideos: getFilteredVideos,
		editVideo: editVideo,
		editPlaylist: editPlaylist,
		tags: tags,
		setTags: setTags,
		getFeaturedTags: getFeaturedTags,
		languages: languages,
		setLanguages: setLanguages,
		getFeaturedLanguages: getFeaturedLanguages,
		dbLastUpdate: data.last_update,
		updateDatabase: updateDatabase,
		saveData: saveData,
		authenticated: authenticated,
		login: login,
		logout: logout,
		loadDatabase: loadDatabase,
		getVideoBySourceURL: getVideoBySourceURL,
		settings: config.settings,
		updateSettings: updateSettings,
		registerFcmToken: registerFcmToken,
		readFcmTokens: readFcmTokens,
		updateFcmSettings: updateFcmSettings,
		updateFcmSuccessFailureCount: updateFcmSuccessFailureCount,
		createFcmMessage: createFcmMessage,
		setFcmMessageClicked: setFcmMessageClicked,
		initialized: true,
	};
	return (
		<AppContext.Provider
			authenticated={props.authenticated}
			setAuthenticated={props.setAuthenticated}
			value={context}
		>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppContext;
