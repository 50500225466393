
import { useState, Fragment } from 'react';
import { IonToast } from '@ionic/react';

function ConnectionToast(props) {
	const [ isOnline, setIsOnline ] = useState(navigator.onLine);
	const [ isBackOnline, setIsBackOnline ] = useState(false);

    window.ononline = (evt) => {
        setIsBackOnline(true);
        setIsOnline(true);
    }

    window.onoffline = (evt) => {
        setIsOnline(false);
    }

    return (
        <Fragment>
            <IonToast isOpen={!isOnline}
                      color="warning"
                      message="You are currently offline!"/>
            <IonToast isOpen={isBackOnline}
                      message="You are back online!"
                      color="success"
                      onDidPresent={() => setTimeout(() => setIsBackOnline(false), 2000)}/>
        </Fragment>
    );
}

export default ConnectionToast;