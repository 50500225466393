import { useContext } from 'react';
import { Redirect } from 'react-router';
import AppContext from './AppContext';

function RequireAuth({children}) {
	const appCtx = useContext(AppContext);
	if (!appCtx.authenticated) return <Redirect to="/" />;
    return children;
}

export default RequireAuth;