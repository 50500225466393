import { useState, useContext, useEffect } from 'react';
import AppContext from '../components/core/AppContext';
import CardsGrid from '../components/layout/CardsGrid';
import { Helmet } from 'react-helmet';

import VideoToolbar from '../components/ui/VideoToolbar';

function VideosPage(props) {
	function editHandler() {
		setMode('edit');
	}

	function saveHandler() {
		appCtx
			.editPlaylist({
				id: currentPlaylist.id,
				is_featured: (featured && '1') || '0'
			})
			.then(discardHandler);
	}

	function discardHandler() {
		setFeatured(currentPlaylist.is_featured === '1');
		setMode('readonly');
	}

	function unFeatureHandler() {
		setFeatured(false);
	}

	function featureHandler() {
		setFeatured(true);
	}

	const appCtx = useContext(AppContext);
	const currentPlaylist = props.playlists[0];

	const [ mode, setMode ] = useState('readonly');
	const [ featured, setFeatured ] = useState(currentPlaylist.is_featured === '1');

	useEffect(() => props.breadcrumbsRef.current.scrollTo(1000, 0), [ currentPlaylist ]);

	return (
		<div>
			<Helmet>
				{props.playlists.length === 1 && <title>BasselTech - {currentPlaylist.title}</title>}

				{props.playlists.length === 1 && <meta name="description" content={currentPlaylist.description} />}

				<meta
					name="keywords"
					content={props.playlists
						.flatMap((playlist) => playlist.title.split(' '))
						.concat(props.playlists.flatMap((playlist) => playlist.description.split(' ')))
						.concat(props.videos.flatMap((video) => video.title.split(' ')))
						.concat(props.videos.flatMap((video) => video.tags))
						.concat(props.videos.flatMap((video) => video.languages))
						.join(',')}
				/>
			</Helmet>

			{props.breadcrumbs}
			{props.playlists.length === 1 && (
				<VideoToolbar
					mode={mode}
					featured={featured}
					editHandler={editHandler}
					saveHandler={saveHandler}
					discardHandler={discardHandler}
					featureHandler={featureHandler}
					unFeatureHandler={unFeatureHandler}
				/>
			)}
			<CardsGrid urlPrefix="v" contentList={props.videos} />
		</div>
	);
}

export default VideosPage;
