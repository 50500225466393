import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonFooter,
	IonButton,
	IonIcon
} from '@ionic/react';

import { useState } from 'react';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import classes from './ConfigModal.module.css';
import { saveOutline } from 'ionicons/icons';

function ConfigModal(props) {
	const [json, setJson] = useState(props.json);

	function saveHandler() {
		props.saveHandler(json);
	}

	function dismissHandler() {
		props.dismissHandler();
		setJson(props.json);
	}

	return (
		<IonModal isOpen={props.showModal} onDidDismiss={dismissHandler}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{props.title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<Editor value={json} onChange={setJson} />
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonButton onClick={saveHandler} color="primary" fill="clear" slot="start">
						<IonIcon icon={saveOutline} slot="start"/> Save
					</IonButton>

					<IonButton
						onClick={dismissHandler}
						color="secondary"
						fill="clear"
						slot="end"
					>
						Discard
					</IonButton>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
}

export default ConfigModal;