import classes from './About.module.css';

import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonIcon } from '@ionic/react';

import Separator from '../components/ui/Separator';

import { Helmet } from 'react-helmet';
import { logoLinkedin } from 'ionicons/icons';

function AboutPage() {
	return (
		<section>
			<Helmet>
				<title>BasselTech - About Us</title>
				<meta
					name="description"
					content="Learn more about BasselTech's mission & Bassel Elerian, the founder of BasselTech."
				/>
				<meta name="keywords" content="basseltech,vision,mission,about,bassel,akmal,elerian,shebak" />
			</Helmet>
			<h2>About Bassel</h2>
			<IonCard className={classes.card}>
				<IonCardHeader>
					<IonCardTitle>
						<div className={classes.avatar} />
					</IonCardTitle>
					<IonCardSubtitle className="ion-text-center">Bassel A. Elerian</IonCardSubtitle>
				</IonCardHeader>
				<IonCardContent>
					<h2>Meet Bassel Elerian, the founder of BasselTech.</h2>
					<p>
						<strong>Bassel</strong> is a software engineer who graduated from the Faculty of
						Engineering at Cairo University in 2019.
					</p>

					<p>
						He is currently working as a full-stack software engineer at
						<a href="https://www.linkedin.com/company/shebak" target="_blank" rel="noreferrer">
							Shebak
						</a>, a software house that provides enterprise software & ERP solutions based on
						<a href="https://www.odoo.com/" target="_blank" rel="noreferrer">
							Odoo platform
						</a>.
					</p>

					<p>
						He started learning software and web development when he was 14 years old, and ever since, he
						started making online YouTube video tutorials to teach the basics of programming, web development
						and IT-related concepts.
					</p>

					<p>
						He has {new Date().getFullYear() - 2020}+ years of professional experience in the software
						development & IT industry and {new Date().getFullYear() - 2010}+ years of experience as
						a professional YouTube IT instructor.
					</p>

					<IonButton
						fill="clear"
						href="https://eg.linkedin.com/in/basselakmal"
						target="_blank"
						rel="noreferrer"
					>
						<IonIcon icon={logoLinkedin} slot="start"/> LinkedIn Profile
					</IonButton>
				</IonCardContent>
			</IonCard>

			<Separator />

			<h2>Our Mission</h2>
			<IonCard className={classes.card}>
				<IonCardContent>
					<p>
						Provide a free learning source for different programming languages, Web Development, and
						Electronics & Logic Design through free online YouTube video tutorials that show a step-by-step
						guide to professional and premium software coding, web development and circuits design.
					</p>
				</IonCardContent>
			</IonCard>
		</section>
	);
}

export default AboutPage;
