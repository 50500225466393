import { useContext } from 'react';

import AppContext from '../core/AppContext';
import ConfigCard from '../common/ConfigCard';
import { createOutline } from 'ionicons/icons';

function SettingsCard(props) {
	const appCtx = useContext(AppContext);

	function updateJson(newJson) {
		return appCtx.updateSettings(newJson);
	}

	return (
		<ConfigCard
			className={props.className}
			title="Settings"
			icon={createOutline}
			json={appCtx.settings}
			updateJson={updateJson}
		/>
	);
}

export default SettingsCard;