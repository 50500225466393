import classes from './NavbarMobile.module.css';

import { IonMenu, IonHeader, IonToolbar, IonContent, IonTitle, IonList, IonItem, IonLabel } from '@ionic/react';

import { Link } from 'react-router-dom';

function NavbarMobile(props) {
	function dismissMenu() {
		document.querySelector('ion-menu').close();
	}

	return (
		<IonMenu side="end" contentId="main" menuId="mainMenu">
			<IonHeader>
				<IonToolbar className={classes.toolbar}>
					<IonTitle className={classes.title}>Main Menu</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList className={classes.list}>
					{props.menuItems.map((menuItem, i) => (
						<Link to={menuItem.url} title={menuItem.title} key={i}>
							<IonItem onClick={dismissMenu}>
								<IonLabel>{menuItem.title}</IonLabel>
							</IonItem>
						</Link>
					))}
				</IonList>
			</IonContent>
		</IonMenu>
	);
}

export default NavbarMobile;
