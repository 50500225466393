import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useContext } from 'react';

import AppContext from '../components/core/AppContext';
import ModalCard from '../components/dashboard/ModalCard';
import DatabaseCard from '../components/dashboard/DatabaseCard';
import WelcomeCard from '../components/dashboard/WelcomeCard';
import SettingsCard from '../components/dashboard/SettingsCard';

import { Helmet } from 'react-helmet';

import classes from './Dashboard.module.css';

function DashboardPage() {
	const appCtx = useContext(AppContext);

	return (
		<div>
			<Helmet>
				<title>BasselTech - Dashboard</title>
				<meta name="description" content="BasselTech Admin Dashboard" />
			</Helmet>
			<IonGrid>
				<IonRow>
					<IonCol sizeXs="12" sizeMd="6">
						<WelcomeCard className={classes.card} />
					</IonCol>
					<IonCol sizeXs="12" sizeMd="6">
						<DatabaseCard className={classes.card} />
					</IonCol>
					<IonCol sizeXs="12" sizeMd="12">
						<SettingsCard />
					</IonCol>
					<IonCol sizeXs="12" sizeMd="6">
						<ModalCard
							title="Manage Tags"
							items={appCtx.tags}
							setItems={appCtx.setTags}
							urlPostfix="tags"
						/>
					</IonCol>
					<IonCol sizeXs="12" sizeMd="6">
						<ModalCard
							title="Manage Languages"
							items={appCtx.languages}
							setItems={appCtx.setLanguages}
							urlPostfix="languages"
						/>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}

export default DashboardPage;
