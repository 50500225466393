import { Link } from 'react-router-dom';
import classes from './BrowseBySnippet.module.css';
import { IonChip, IonButton, useIonPicker, IonIcon } from '@ionic/react';
import { addOutline, closeOutline } from 'ionicons/icons';

function BrowseBySnippet(props) {
	const setSelectedItems = props.setState;
	const selectedItems = props.contentList;
	const mode = props.mode;

	const [ present ] = useIonPicker();

	function getUnselectedItems() {
		return props.allItems.filter((item) => !selectedItems.includes(item.text)).map((item) => item.text);
	}

	function removeItemHandler(evt) {
		var newSelectedItems = selectedItems.map((item) => item);
		newSelectedItems.splice(parseInt(evt.target.dataset.key), 1);
		setSelectedItems(newSelectedItems);
	}

	function addItemHandler() {
		present({
			buttons: [
				{
					text: 'Confirm',
					handler: (selected) => {
						setSelectedItems([ selected[props.title].text ].concat(selectedItems));
					}
				}
			],
			columns: [
				{
					name: props.title,
					options: getUnselectedItems().map((item) => {
						return { text: item, value: item };
					})
				}
			]
		});
	}

	return (
		<div className={props.icon && classes.item}>
			{(props.icon && (
				<span className={classes.item_icon} title={props.title}>
					<IonIcon icon={props.icon}/>
				</span>
			)) || <h2>{props.title}</h2>}

			<div className={`${classes.item_chips} ${props.icon && classes.has_icon}`}>
				{selectedItems.map(
					(item, i) =>
						(mode === 'edit' && (
							<IonChip key={i}>
								{item} <IonIcon icon={closeOutline} data-key={i} onClick={removeItemHandler} />
							</IonChip>
						)) || (
							<Link
								key={i}
								to={`/watch?${props.urlPrefix}=${encodeURIComponent(item)}`}
								title={item}
								className={classes.item_link}
							>
								<IonChip key={i}>{item}</IonChip>
							</Link>
						)
				)}
				{mode === 'edit' &&
				getUnselectedItems().length > 0 && (
					<IonButton fill="clear" size="small" onClick={addItemHandler} className="ion-align-self-center">
						<IonIcon icon={addOutline} slot="icon-only"/>
					</IonButton>
				)}
			</div>
		</div>
	);
}

export default BrowseBySnippet;
