import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton } from '@ionic/react';
import { useContext } from 'react';

import AppContext from '../core/AppContext';

import classes from './WelcomeCard.module.css';

function WelcomeCard(props) {
	const appCtx = useContext(AppContext);

	function logoutHandler() {
		appCtx.logout();
	}

	return (
		<IonCard className={props.className}>
			<IonCardHeader>
				<IonCardTitle>
					<span className={classes.title}>Welcome, Bassel!</span>
					<IonButton
						className={classes.right_button}
						fill="clear"
						color="danger"
						size="small"
						onClick={logoutHandler}
					>
						Logout
					</IonButton>
				</IonCardTitle>
				<IonCardContent>
					<p className={classes.quote}>
						“The people who are crazy enough to think they can change the world are the ones who do.”
					</p>
					<p className={classes.right}>— Steve Jobs</p>
				</IonCardContent>
			</IonCardHeader>
		</IonCard>
	);
}

export default WelcomeCard;
