import { IonButtons, IonButton, IonToolbar, IonIcon } from '@ionic/react';
import { useContext } from 'react';
import AppContext from '../core/AppContext';
import classes from './VideoToolbar.module.css';
import { closeOutline, createOutline, saveOutline, star, starOutline } from 'ionicons/icons';

function VideoToolbar(props) {
	const mode = props.mode;
	const featured = props.featured;

	const appCtx = useContext(AppContext);

	if (!appCtx.authenticated) return <div />;

	return (
		<IonToolbar className={classes.toolbar}>
			<IonButtons slot="start">
				{mode === 'readonly' && (
					<IonButton onClick={props.editHandler}>
						<IonIcon icon={createOutline} slot="icon-only"/>
					</IonButton>
				)}
				{mode === 'edit' && (
					<IonButton onClick={props.saveHandler}>
						<IonIcon icon={saveOutline} slot="icon-only"/>
					</IonButton>
				)}
				{mode === 'edit' && (
					<IonButton onClick={props.discardHandler}>
						<IonIcon icon={closeOutline} slot="icon-only"/>
					</IonButton>
				)}
			</IonButtons>
			{mode === 'edit' && (
				<IonButtons slot="end">
					{!featured && (
						<IonButton onClick={props.featureHandler} title="Feature">
							<IonIcon icon={starOutline} slot="icon-only"/>
						</IonButton>
					)}
					{featured && (
						<IonButton onClick={props.unFeatureHandler} title="Un-feature">
							<IonIcon icon={star} slot="icon-only"/>
						</IonButton>
					)}
				</IonButtons>
			)}
		</IonToolbar>
	);
}

export default VideoToolbar;
