import { IonToast } from '@ionic/react';
import { isMobile } from './Utils';
import classes from './ToastPrompt.module.css';

function ToastPrompt({ isOpen, title, message, color, buttons }) {
    let cssClass = `${classes.stacked_toast} ${isMobile() ? '' : classes.desktop_toast}`;
    if (title) {
        message = `
            <div>
                <h3>${title}</h3>
                <div>${message}</div>
            </div>`;
        cssClass = `${classes.titled_notification} ${cssClass}`;
    }
    return (
        <IonToast
            isOpen={isOpen}
            message={message}
            color={color || 'tertiary'}
            cssClass={cssClass}
            position={isMobile() ? "bottom" : "top"}
            buttons={buttons}
        />
    )
}

export default ToastPrompt;