import VideoPage from './Video';
import VideosPage from './Videos';

import Separator from '../components/ui/Separator';
import Breadcrumbs from '../components/ui/Breadcrumbs';
import TutorialsSnippet from '../components/snippets/TutorialsSnippet';

import { useLocation } from 'react-router-dom';
import BrowseBySnippet from '../components/snippets/BrowseBySnippet';
import { useContext, useRef, useEffect } from 'react';
import AppContext from '../components/core/AppContext';

import { Helmet } from 'react-helmet';

function WatchPage(props) {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const appCtx = useContext(AppContext);
	const breadcrumbsRef = useRef(null);

	const playlists = appCtx.playlists;
	const tags = appCtx.tags.map((tag) => tag.text);
	const languages = appCtx.languages.map((language) => language.text);

	const [ matchedPlaylists, matchedVideos ] = appCtx.getFilteredVideos(searchParams);

	const BreadcrumbsJSX = () => <Breadcrumbs playlists={matchedPlaylists} videos={matchedVideos} breadcrumbsRef={breadcrumbsRef} />;

	if (matchedVideos.length > 0) {
		return (
			<div>
				{matchedVideos.length === 1 && (
					<VideoPage playlists={matchedPlaylists} videos={matchedVideos} breadcrumbs={BreadcrumbsJSX()} breadcrumbsRef={breadcrumbsRef} />
				)}
				{matchedVideos.length > 1 && (
					<VideosPage playlists={matchedPlaylists} videos={matchedVideos} breadcrumbs={BreadcrumbsJSX()} breadcrumbsRef={breadcrumbsRef} />
				)}
			</div>
		);
	}

	return (
		<div>
			<Helmet>
				<title>BasselTech - Tutorials</title>

				<meta name="description" content="BasselTech IT & programming tutorials list." />

				<meta
					name="keywords"
					content={playlists
						.flatMap((playlist) => playlist.title.split(' '))
						.concat('BasselTech IT Technology Tutorials'.split(' '))
						.concat(playlists.flatMap((playlist) => playlist.description.split(' ')))
						.concat(tags)
						.concat(languages)
						.join(',')}
				/>
			</Helmet>
			{tags.length > 0 && (
				<section>
					<BrowseBySnippet title="Browse Tags" urlPrefix="tags" contentList={tags} allItems={tags} />
					<Separator />
				</section>
			)}

			{languages.length > 0 && (
				<section>
					<BrowseBySnippet
						title="Browse Languages"
						urlPrefix="languages"
						contentList={languages}
						allItems={languages}
					/>
					<Separator />
				</section>
			)}
			<section>
				<TutorialsSnippet title="All Playlists" urlPrefix="list" contentList={playlists} allItems={playlists} />
			</section>
		</div>
	);
}

export default WatchPage;
